import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import firebase from 'firebase/app'

//import VConsole from 'vconsole'

Vue.config.productionTip = false

var firebaseConfig = {
  apiKey: "AIzaSyCuRANkGu837vhN4yjn2xpcDnYdnakSGGY",
  authDomain: "moonwalk-shibuyahyakkendana.firebaseapp.com",
  projectId: "moonwalk-shibuyahyakkendana",
  storageBucket: "moonwalk-shibuyahyakkendana.appspot.com",
  messagingSenderId: "761027994504",
  appId: "1:761027994504:web:b5d46755a42adfee61503d"
};
firebase.initializeApp(firebaseConfig);

// vconsole
//new VConsole()

new Vue({
  router,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
