<script>
import axios from 'axios'
import firebase from 'firebase/app'
require('firebase/auth')
import LoadingMessage from '@/components/LoadingMessage'

export default {
  components: {
    LoadingMessage
  },
  data() {
    return {
      isClient: false,
      message: '',
      snackbarFlag: false,
      snackbarTimeout: 3000,
      snackbarColor: 'success',
      snackbarText: '',
      loading: false,
      success: false,
      required: value => !!value || "必ず入力してください",
      loadingInfo: {
        loading: true,
        message: '読み込み中...'
      },

    }
  },
  created() {
    this.loginCheck()
  },
  methods: {
    loginCheck() {
      var me = this
      this.isClient = liff.isInClient()

      if (this.isClient) {
          this.initialize()
      } else {
        firebase.auth().onAuthStateChanged(function (user) {
          if (user) {
            me.initialize()
          } else {
            // ログインしてない
            me.$router.push('/manager/signin').catch(()=>{})
          }
        })
      }
    },
    initialize() {
      var me = this
      axios.post(process.env.VUE_APP_YOYAKU_MESSAGE_API)
        .then(response => {
          if (response.data.message.length > 0) {
            me.message = response.data.message
          }
          me.loadingInfo.loading = false
        }).catch(error => {
          me.loadingInfo.loading = false
          me.showSnackbar('情報の取得に失敗しました', 'error')
          console.log(error);
        });

    },
    submit() {
      var me = this
      if (this.$refs.send_data.validate()) {
        this.loading = true

        const publicKeyStr = process.env.VUE_APP_CRYPTO_PUBLIC_KEY.replace(/\\n/g, '\n')
        const publicKey = KEYUTIL.getKey(publicKeyStr)

        const headerName = KJUR.crypto.Cipher.encrypt(process.env.VUE_APP_HEADER_NAME, publicKey, 'RSA')

        const params = new URLSearchParams()
        params.append('message', this.message)

        axios.post(process.env.VUE_APP_SET_YOYAKU_MESSAGE_API, params, {
          headers: {
            'X-Line-Signature': headerName
          }
        })
          .then(response => {
            me.loading = false

            if (response.status == 200) {
              me.showSnackbar('登録完了しました', 'success')
            } else {
              me.showSnackbar('登録に失敗しました', 'error')

            }

          }).catch(error => {
            console.log(error);
            me.loading = false
            me.showSnackbar('送信に失敗しました', 'error')
        });
      }
    },
    signOut() {
      var me = this
      firebase.auth().signOut().then(()=> {
        me.$router.replace('/manager/signin').catch(()=>{})
      })
      .catch( (error)=>{
        console.log(`ログアウト時にエラーが発生しました (${error})`);
      });
    },
    showSnackbar(text, color) {
      this.snackbarFlag = true
      this.snackbarColor = color
      this.snackbarText = text
    }
  }
}
</script>
<template>
  <v-app>
    <header v-if="!isClient">
      <v-app-bar app>
        <v-toolbar-title>bar moon walk 渋谷百軒店店 管理画面</v-toolbar-title>
        <div class="flex-grow-1"></div>
          <v-btn color="info" class="font-weight-bold mr-6" :to="{ name: 'ManagerMain' }">メインメニュー</v-btn>
          <v-btn color="success" class="font-weight-bold" v-on:click="signOut">ログアウト</v-btn>
      </v-app-bar>
    </header>

    <v-main>
      <v-container>
        <v-card
          class="mx-auto"
          max-width="600"
        >
          <v-container fluid>
            <v-card-text>
              <v-form ref="send_data">
                <v-textarea v-model="message" label="予約時確認メッセージ" outlined :rules="[required]" :disabled="loading"/>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="success" @click="submit" :loading="loading">
                    登録する
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card-text>
          </v-container>
        </v-card>
      </v-container>
      <!-- loading -->
      <div>
        <loading-message
          :loadingInfo="loadingInfo"
        ></loading-message>
      </div>
      <!-- Snackbar -->
      <div>
        <v-snackbar
          v-model="snackbarFlag"
          :color="snackbarColor"
          :timeout="snackbarTimeout"
          :multi-line="true"
          top
        >
          {{ snackbarText }}
          <template v-slot:action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="snackbarFlag = false"
            >
              <v-icon
              right
              >
              mdi-close-circle-outline
            </v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </div>

    </v-main>
  </v-app>
</template>