<script>
import firebase from 'firebase/app'
require('firebase/auth')
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
import jaLocale from "@fullcalendar/core/locales/ja"
import axios from 'axios'
const moment = require("moment");
import DialogCard from '@/components/NGDialogCard'
import LoadingMessage from '@/components/LoadingMessage'

export default {
  components: {
    FullCalendar,
    DialogCard,
    LoadingMessage
  },
  data() {
    return {
      isClient: false,
      snackbarFlag: false,
      snackbarTimeout: 3000,
      snackbarText: '',
      user: {
        st_tm: '',
        color: '',
        indexNo: -1
      },
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, googleCalendarPlugin ],
        locale: jaLocale,
        firstDay: 1,
        initialView: 'dayGridMonth',
        contentHeight: 'auto',
        weekends: true,
        height: 'auto',
        googleCalendarApiKey: process.env.VUE_APP_GOOGLE_API_KEY,
        // eventTimeFormat: { hour: 'numeric', minute: '2-digit' },
        // displayEventEnd: true,
        displayEventTime: false,
        eventSources: [
          {
            googleCalendarId: 'ja.japanese#holiday@group.v.calendar.google.com',
            className: 'event_holiday',
            success: function (events) {
              events.forEach(event => {
                event.title=`${event.title}`
                event.color="red"
                event.description=process.env.VUE_APP_HOLIDAY_WORD
                event.url = ""
              });
            }
          }
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        eventClick: this.eventClick
      },
      dialog: false,
      loadingInfo: {
        loading: true,
        message: '読み込み中...'
      },
      calendarData: {
        targetInfo: {},
        title: '',
        st_tm: '',
        ed_tm: '',
        location: '',
        ngList: [],
        description: ''
      }
    }
  },
  created() {
    this.loginCheck()
  },
  methods: {
    loginCheck() {
      var me = this
      this.isClient = liff.isInClient()

      if (this.isClient) {
          this.getSatelliteEvent()
      } else {
        firebase.auth().onAuthStateChanged(function (user) {
          if (user) {
            me.show = true
            me.getSatelliteEvent()
          } else {
            // ログインしてない
            me.$router.push('/manager/signin').catch(()=>{})
          }
        })
      }
    },
    signOut() {
      var me = this
      firebase.auth().signOut().then(()=> {
        me.$router.replace('/manager/signin').catch(()=>{})
      })
      .catch( (error)=>{
        console.log(`ログアウト時にエラーが発生しました (${error})`);
      });
    },
    // 予約可能枠取得
    getSatelliteEvent: function () {
      const me = this;
      axios.post(process.env.VUE_APP_NGSCHEDULE_CALENDAR_API)
        .then(response => {
          const scheduleList = response.data.schedule
          const events = {
            events: scheduleList
          }
          me.calendarOptions.eventSources.push(events)
          me.calendarData.ngList = response.data.ngList;
          me.loadingInfo.loading = false
        }).catch(error => {
          console.log(error);
        });
    },
    eventClick: function (info) {
      const stDate = moment(info.event.start)
      const edDate = moment(info.event.end)
      if (info.event.extendedProps.description === process.env.VUE_APP_HOLIDAY_WORD) {
        this.showSnackbar(info.event.title)
      } else {
        this.calendarData.description = info.event.extendedProps.description
        this.calendarData.location = info.event.extendedProps.location
        this.calendarData.st_tm = stDate
        this.calendarData.ed_tm = edDate
        this.calendarData.title = info.event.extendedProps.summary
        this.calendarData.targetInfo = info
        this.dispDialog()
      }
    },
    dispDialog() {
      this.dialog = true
    },
    onClose() {
      this.dialog = false
    },
    onSubmit(params) {
      var me = this
      this.dialog = false
      this.user.st_tm = moment(params.selected)

      if (params.ngItem != null) {
        // NG枠削除
        this.loadingInfo.loading = true
        this.loadingInfo.message = '処理中...'

        const indexNo = this.calendarData.ngList.indexOf(params.ngItem)
        const forms = new URLSearchParams()
        forms.append('uuid', params.ngItem.id)

        axios.post(process.env.VUE_APP_DELETE_NGSCHEDULE_API, forms)
        .then(response => {
          if (response.status == 200) {
            me.loadingInfo.loading = false
            this.calendarData.ngList.splice(indexNo, 1)
            this.eventClick(this.calendarData.targetInfo)
          }
        })
        
      } else {
        // NG枠登録
        this.loadingInfo.loading = true
        this.loadingInfo.message = '処理中...'

        const st_tm = moment(params.selected).format('YYYY-MM-DD HH:mm')
        const ed_tm = moment(st_tm).add(process.env.VUE_APP_INTERVAL, 'm').format('YYYY-MM-DD HH:mm')

        const forms = new URLSearchParams()
        forms.append('title', 'NG枠')
        forms.append('st', st_tm)
        forms.append('ed', ed_tm)
        forms.append('allFlg', 'N')

        axios.post(process.env.VUE_APP_REGIST_NGSCHEDULE_API, forms)
        .then(response => {
          if (response.status == 200) {
            me.loadingInfo.loading = false
            this.calendarData.ngList.push(response.data)
            this.eventClick(this.calendarData.targetInfo)
          }
        })
      }
    },
    onSubmitAll(params) {
      var me = this
      this.dialog = false
      this.loadingInfo.loading = true
      this.loadingInfo.message = '処理中...'

      if (params.isAllFlg != undefined) {
        // NG枠削除
        const indexNo = this.calendarData.ngList.indexOf(params.isAllFlg)
        const forms = new URLSearchParams()
        forms.append('uuid', params.isAllFlg.id)

        axios.post(process.env.VUE_APP_DELETE_NGSCHEDULE_API, forms)
        .then(response => {
          if (response.status == 200) {
            me.loadingInfo.loading = false
            me.calendarData.ngList.splice(indexNo, 1)
            this.eventClick(this.calendarData.targetInfo)
          }
        })

      } else {
        // 終日NGにする
        const st_tm = moment(params.selected).format('YYYY-MM-DD')
        const ed_tm = st_tm

        const forms = new URLSearchParams()
        forms.append('title', params.openTitle)
        forms.append('st', st_tm)
        forms.append('ed', ed_tm)
        forms.append('allFlg', 'Y')

        axios.post(process.env.VUE_APP_REGIST_NGSCHEDULE_API, forms)
        .then(response => {
          if (response.status == 200) {
            me.loadingInfo.loading = false
            this.calendarData.ngList.push(response.data)
            this.eventClick(this.calendarData.targetInfo)
          }
        })

      }

    },
    showSnackbar(text) {
      this.snackbarFlag = true
      this.snackbarText = text
    }
  }
}
</script>
<style>
.fc-daygrid-event {
  white-space: normal!important;
  word-wrap:break-word!important;
}
</style>
<template>
  <v-app>
    <header v-if="!isClient">
      <v-app-bar app>
        <v-toolbar-title>bar moon walk 渋谷百軒店店 管理画面</v-toolbar-title>
        <div class="flex-grow-1"></div>
          <v-btn color="info" class="font-weight-bold mr-6" :to="{ name: 'ManagerMain' }">メインメニュー</v-btn>
          <v-btn color="success" class="font-weight-bold" v-on:click="signOut">ログアウト</v-btn>
      </v-app-bar>
    </header>

    <v-main>
      <v-container class="text-center">
        <h3>予約NG日時を選択してください</h3>
        <v-row>
          <v-col>
          <FullCalendar :options="calendarOptions"/>
          </v-col>
        </v-row>
        <!-- dialog -->
        <div>
          <v-dialog v-model="dialog" width="600px" scrollable>
            <dialog-card
              v-on:clickSubmit="onSubmit"
              v-on:clickSubmitAll="onSubmitAll"
              v-on:clickClose="onClose"
              :calendarData="calendarData"
            ></dialog-card>
          </v-dialog>
        </div>
        <!-- loading -->
        <div>
          <loading-message
            :loadingInfo="loadingInfo"
          ></loading-message>
        </div>
        <!-- Snackbar -->
        <div>
          <v-snackbar
            v-model="snackbarFlag"
            color="error"
            :timeout="snackbarTimeout"
            :multi-line="true"
            top
          >
            {{ snackbarText }}
            <template v-slot:action="{ attrs }">
              <v-btn
                text
                v-bind="attrs"
                @click="snackbarFlag = false"
              >
                <v-icon
                right
                >
                mdi-close-circle-outline
              </v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>