<template>
  <v-card width="650px" class="mx-auto mt-5">
    <v-container>
      <v-card-title>
        <h1 v-if="user.manage_id.length == 0" class="display-1">予約確認</h1>
        <h1 v-else class="display-1">予約変更確認</h1>

      </v-card-title>
      <v-card-subtitle v-if="user.manage_id.length == 0" >下記内容で登録します。修正する場合は「修正する」ボタンを押してください。</v-card-subtitle>
      <v-card-subtitle v-else >下記内容へ変更します。修正する場合は「修正する」ボタンを押してください。</v-card-subtitle>

      <v-card-text>日時: {{st_tm}}〜</v-card-text>

      <v-row>
        <v-col>
          <v-card-text v-if="user.manage_id.length == 0">
            <v-text-field v-model="user.course" label="希望内容" outlined readonly />
            <v-text-field v-model="user.name" label="お名前" outlined readonly/>
            <v-text-field v-model="user.phone" label="電話番号（固定電話・携帯番号）" outlined readonly/>
            <v-text-field v-model="user.email" label="メールアドレス" outlined readonly/>
            <v-text-field v-model="user.member_cnt" label="予約人数" outlined readonly />
            <v-textarea v-model="user.note" label="当店へのメッセージ" outlined readonly/>
          </v-card-text>
          <v-card-text v-else>
            <v-text-field v-model="user.course" label="希望内容" outlined readonly />
            <v-text-field v-model="user.member_cnt" label="予約人数" outlined readonly />
            <v-textarea v-model="user.note" label="当店へのメッセージ" outlined readonly/>
          </v-card-text>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn color="error" large block class="font-weight-bold" v-on:click="$router.back()" :disabled="loading">修正する</v-btn>
        </v-col>
        <v-col>
          <v-btn v-if="user.manage_id.length == 0" color="success" large block class="font-weight-bold" v-on:click="submit" :loading="loading">登録する</v-btn>
          <v-btn v-else color="success" large block class="font-weight-bold" v-on:click="submit" :loading="loading">変更する</v-btn>
        </v-col>
      </v-row>
      <!-- Snackbar -->
      <div>
        <v-snackbar
          v-model="snackbarFlag"
          color="error"
          :timeout="snackbarTimeout"
          :multi-line="true"
          top
        >
          {{ snackbarText }}
          <template v-slot:action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="snackbarFlag = false"
            >
              <v-icon
              right
              >
              mdi-close-circle-outline
            </v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    user: Object
  },
  data() {
    return {
      snackbarFlag: false,
      snackbarTimeout: 3000,
      snackbarText: '',
      loading: false
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.loading) {
      next(false)
    } else {
      next()
    }
  },
  computed: {
    st_tm: {
      get: function() {
        return this.user.st_tm.format('YYYY年MM月DD日 HH:mm')
      }
    }
  },
  methods: {
    confirmSave(event) {
      const answer = window.confirm("編集中のものは保存されませんが、よろしいですか？")
      if (answer) {
        next()
      } else {
        next(false)
      }
    },
    submit() {
      this.loading = true
      const me = this;
      
      const publicKeyStr = process.env.VUE_APP_CRYPTO_PUBLIC_KEY.replace(/\\n/g, '\n')
      const publicKey = KEYUTIL.getKey(publicKeyStr)

      // 暗号化
      const name = (this.user.manage_id.length == 0)? KJUR.crypto.Cipher.encrypt(this.user.name, publicKey, 'RSA'): ""
      const phone = (this.user.manage_id.length == 0)? KJUR.crypto.Cipher.encrypt(this.user.phone, publicKey, 'RSA'): ""
      const email = (this.user.manage_id.length == 0)? KJUR.crypto.Cipher.encrypt(this.user.email, publicKey, 'RSA'): ""

      const send_data = {
        uuid: this.user.uuid,
        manage_id: this.user.manage_id,
        token: this.user.token,
        course: this.user.course,
        name: name,
        phone: phone,
        email: email,
        st_tm: this.user.st_tm,
        note: this.user.note,
        member_cnt: this.user.member_cnt,
        isClient: this.user.isClient
      }
      
      const params = new URLSearchParams()
      params.append('user_data', JSON.stringify(send_data))

      var targetURL = (this.user.manage_id.length == 0 ? process.env.VUE_APP_REGIST_SCHEDULE_API : process.env.VUE_APP_UPDATE_SCHEDULE_API)
      axios.post(targetURL, params)
        .then(response => {
          me.loading = false

          if (response.status == 200) {
            me.$router.replace('/done')
          } else {
            me.loading = false
            me.showSnackbar("登録に失敗しました。")
          }

        }).catch(error => {
          console.log(error);
          me.loading = false
          me.showSnackbar("登録に失敗しました。")
      });
    },
    showSnackbar(text) {
      this.snackbarFlag = true
      this.snackbarText = text
    }
  }
}
</script>