<script>
import firebase from 'firebase/app'
require('firebase/auth')

export default {
  data () {
    return {
      cards: [
        { title: '予約者情報閲覧', flex: 12, name: 'ReserveList', color: '#ffefd5'},
        { title: 'NG枠登録', flex: 12, name: 'NGCalendar', color: '#e0ffff' },
        { title: '予約時メッセージ登録', flex: 12, name: 'YoyakuMessage', color: '#e9967a' }
      ]
    }
  },
  created() {
    this.loginCheck()
  },
  methods: {
    loginCheck() {
      var me = this
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          me.show = true
        } else {
          // ログインしてない
          me.$router.push('/manager/signin').catch(()=>{})
        }
      })
    },
    signOut() {
      var me = this
      firebase.auth().signOut().then(()=> {
        me.$router.replace('/manager/signin').catch(()=>{})
      })
      .catch( (error)=>{
        console.log(`ログアウト時にエラーが発生しました (${error})`);
      });
    },
    dispReserveList() {
      // 予約者情報ページへ遷移
      this.$router.push('/manager/reserve-list').catch(()=>{})
    },
    dispNGCalendar() {
      // NG枠登録ページへ遷移
      this.$router.push('/manager/ng-calendar').catch(()=>{})
    }

  }
}
</script>

<template>
  <div id="app">
    <v-app id="inspire">
      <header>
        <v-app-bar app>
          <v-toolbar-title>bar moon walk 渋谷百軒店店 管理画面</v-toolbar-title>
          <div class="flex-grow-1"></div>
          <v-btn color="success" class="font-weight-bold" v-on:click="signOut">ログアウト</v-btn>
        </v-app-bar>
      </header>

      <v-main>
        <v-container>
          <v-card
            class="mx-auto"
            max-width="600"
          >
            <v-container fluid>
              <v-row dense>
                <v-col
                  v-for="card in cards"
                  :key="card.title"
                  :cols="card.flex"
                >
                <v-card :color="card.color">
                    <v-btn text block height="200px" :to="{ name: card.name }">
                      <h2>{{card.title}}</h2>
                    </v-btn>
                </v-card>

                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>