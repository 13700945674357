<script>
import axios from 'axios'
const dayjs = require("dayjs");
import firebase from 'firebase/app'
require('firebase/auth')
import ReserveInfo from '@/components/ReserveInfo'
import LoadingMessage from '@/components/LoadingMessage'

export default {
  components: {
    ReserveInfo,
    LoadingMessage
  },
  data() {
    return {
      isClient: false,
      dialog: false,
      search: '',
      reserveData: {},
      sortBy: 'st_tm',
      sortDesc: false,
      headers: [
        { text: 'お名前', value: 'name', align: 'left' },
        { text: '予約日時', value: 'st_tm', align: 'left' },
        { text: '希望内容', value: 'course', align: 'left' },
        { text: '詳細', value: 'actions', align: 'center', sortable: false }
      ],
      loadingInfo: {
        loading: true,
        message: '読み込み中...'
      },
      reserveLists: []
    }
  },
  created() {
    this.loginCheck()
  },
  methods: {
    loginCheck() {
      var me = this
      this.isClient = liff.isInClient()

      if (this.isClient) {
          this.initialize()
      } else {
        firebase.auth().onAuthStateChanged(function (user) {
          if (user) {
            me.show = true
            me.initialize()
          } else {
            // ログインしてない
            me.$router.push('/manager/signin').catch(()=>{})
          }
        })
      }
    },
    initialize () {
      var me = this
      const publicKeyStr = process.env.VUE_APP_CRYPTO_PUBLIC_KEY.replace(/\\n/g, '\n')
      const publicKey = KEYUTIL.getKey(publicKeyStr)

      const headerName = KJUR.crypto.Cipher.encrypt(process.env.VUE_APP_HEADER_NAME, publicKey, 'RSA')

      // 予約者情報取得
      axios.post(process.env.VUE_APP_SEARCH_RESERVE_INFO_API, {},{
        headers: {
          'X-Line-Signature': headerName
        }
      })
        .then(response => {
          me.loadingInfo.loading = false
          if (response.data.reserve_info.length > 0) {
            me.reserveLists = response.data.reserve_info
          }
        }).catch(error => {
          console.log(error)
          me.loadingInfo.loading = false
      });
    },
    reload() {
      this.loadingInfo.loading = true
      this.reserveLists = []
      this.initialize()
    },
    dispDialog() {
      this.dialog = true
    },
    onClose() {
      this.dialog = false
    },
    reserveInfo(info) {
      this.reserveData = info
      this.dispDialog()
    },
    signOut() {
      var me = this
      firebase.auth().signOut().then(()=> {
        me.$router.replace('/manager/signin').catch(()=>{})
      })
      .catch( (error)=>{
        console.log(`ログアウト時にエラーが発生しました (${error})`);
      });
    }
  }

}
</script>
<template>
  <v-app>
    <header v-if="!isClient">
      <v-app-bar app>
        <v-toolbar-title>bar moon walk 渋谷百軒店店 管理画面</v-toolbar-title>
        <div class="flex-grow-1"></div>
          <v-btn color="info" class="font-weight-bold mr-6" :to="{ name: 'ManagerMain' }">メインメニュー</v-btn>
          <v-btn color="success" class="font-weight-bold" v-on:click="signOut">ログアウト</v-btn>
      </v-app-bar>
    </header>

    <v-main>
      <v-container>
        <v-card class="mx-auto mt-5">
          <v-container>
            <v-card-title>
              <h1 class="display-1">予約者情報一覧</h1>
            </v-card-title>

            <v-card-title class="title">
              <v-text-field
                v-model="search"
                label="検索"
                single-line
                outline
                clearable
              >
              <template v-slot:append-outer>
                <v-btn icon @click="reload"><v-icon>mdi-reload</v-icon></v-btn>
              </template>
              </v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="reserveLists"
              :search="search"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :items-per-page="50"
              :loading="loadingInfo.loading"
              loading-text="予約者情報取得中..."
              @click:row="reserveInfo"
              class="elevation-1"
            >
              <template v-slot:[`item.actions`]="{item}">
                <v-icon
                  small
                  @click="reserveInfo(item)"
                >
                  mdi-clipboard-account
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">Reset</v-btn>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-container>
      <!-- dialog -->
      <div>
        <v-dialog v-model="dialog" width="600px" scrollable>
          <reserve-info
            v-on:clickClose="onClose"
            :reserveData="reserveData"
          ></reserve-info>
        </v-dialog>
      </div>
    </v-main>
  </v-app>
</template>