import Vue from 'vue'
import VueRouter from 'vue-router'

import Rules from '../views/Rules.vue'
import Index from '../views/Index.vue'
import Form from '../views/Form.vue'
import Confirm from '../views/Confirm.vue'
import Calendar from '../views/Calendar.vue'
import Done from '../views/Done.vue'

// 管理画面
import ManagerSignIn from '../views/manager/SignIn.vue'
import ManagerMain from '../views/manager/ManagerMain.vue'
import NGCalendar from '../views/manager/NGCalendar.vue'
import ReserveList from '../views/manager/ReserveList.vue'
import YoyakuMessage from '../views/manager/YoyakuMessage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/rules',
    name: 'Rules',
    component: Rules
  },
  {
    path: '/manager/signin',
    name: 'ManagerSignIn',
    component: ManagerSignIn
  },
  {
    path: '/manager/main',
    name: 'ManagerMain',
    component: ManagerMain
  },
  {
    path: '/manager/ng-calendar',
    name: 'NGCalendar',
    component: NGCalendar
  },
  {
    path: '/manager/reserve-list',
    name: 'ReserveList',
    component: ReserveList
  },
  {
    path: '/manager/yoyakumessage',
    name: 'YoyakuMessage',
    component: YoyakuMessage
  },
  {
    path: '/',
    name: 'Index',
    component: Index,
    children: [
      {
        path: '/calendar',
        component: Calendar
      },
      {
        path: '/form',
        component: Form
      },
      {
        path: '/confirm',
        component: Confirm
      },
      {
        path: '/done',
        component: Done
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

export default router
